$navigation-sidebar-top:                    45%!default;
$navigation-sidebar-position:               left!default; //left or right
$navigation-sidebar-zIndex:                 1001!default;
$navigation-sidebar-link-bg:                $primary!default;
$navigation-sidebar-link-icon-color:        $dark!default;
$navigation-sidebar-link-icon-radius:       50%!default;
$navigation-sidebar-link-icon-font-size:    3.5rem!default;

$navigation-sidebar-modal-top:              43%!default;
$navigation-sidebar-modal-color:            $body-color!default;

.navigation-sidebar {
    position: fixed;
    top: $navigation-sidebar-top;
    @if($navigation-sidebar-position == 'left'){
        left: 0;
    } @else {
        right: 0;
    }
    cursor: pointer;
    z-index: $navigation-sidebar-zIndex;

    &__link {
        padding: 0.5 * $spacer $spacer;
        background-color: $navigation-sidebar-link-bg;
    }

    &__link-img {
        width: 3.5rem;
        height: 3.5rem;

        @include media-breakpoint-down(md) {
            width: 3rem;
            height: 3rem;
        }
    }

    .icon {
        color: $navigation-sidebar-link-icon-color;
        border-radius: $navigation-sidebar-link-icon-radius;
        @include font-size($navigation-sidebar-link-icon-font-size);
    }

    @include media-breakpoint-down(md) {
        top: 0.1 * $spacer;
        right: 0;
        left: auto;

        &__link {
            padding: 0.5*$spacer 0.5*$spacer;
        }
    }

}

.navigation-sidebar__modal {
    padding-left: 0!important;
    bottom: auto;
    top: $navigation-sidebar-modal-top;
    color: $navigation-sidebar-modal-color;

    .modal-dialog {
        @if($navigation-sidebar-position == 'left') {
            margin-left: $spacer;
            margin-right: auto;
        } @else {
            margin-right: $spacer;
            margin-left: auto;
        }

        .modal-header {
            @include headings {
                margin-bottom: 0 !important;
            }
            @include  display-headings {
                margin-bottom: 0 !important;
            }
        }

        .modal-content {

            @include headings {
                color: $primary;
            }

            @include display-headings {
                color: $primary;
            }

            a {
                color: $primary;
            }
        }
    }

    .modal-open & {
        overflow: visible;
    }
}

.info-box {
    top: calc(45% + 4rem);
}

body.modal-open {
    padding-right: 0!important;
    overflow: visible;
}
