$text-module-image-col-gap: $grid-gutter-width !default;

@include media-breakpoint-up(md){
    .text--col-count-2 {
        column-count: 2;
        column-gap: $text-module-image-col-gap;
    }
    .text--col-count-3 {
        column-count: 3;
        column-gap: $text-module-image-col-gap;
    }
    .text--col-count-4 {
        column-count: 4;
        column-gap: $text-module-image-col-gap;
    }
}