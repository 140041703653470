//h1, .h1 {
.text__headings h1, .text__headings .h1, .headlines {
    text-align: center;
    margin-bottom: $spacer*3;
    @if ($heading-seperator) {
        &::after {
            content: "";
            display: block;
            height: 3rem;
            margin-top: $spacer;
            background: str-replace(url(map_get($separationStyles,$separationStyle)), "#", "%23") center no-repeat;
        }
    }
}

.telefon {
    font: $h2-font-size $headings-font-family;
}

footer {
    .address {
        font-size: 15px;
    }
}