.navbar {
    @include media-breakpoint-up(lg) {
        padding: 0;
    }
    background-color: $navbar-light-bg-color;
}

.nav-link {
    letter-spacing: 0.75px;
    transition: $transition-base;
    font-weight: 300;
    &:focus,
    &:hover,
    .active &{
        background-color: darken($primary,5%);
    }
}

.nav-tabs {
    .nav-link {
        background-color: $body-bg;
    }
}

.tab-content {
    padding-bottom: $spacer*2;
    padding-top: $spacer*2;
}

.top-navbar{
    &:not(.scroll-to-fixed-fixed) {
        width: 100%;
    }

    &.scroll-to-fixed-fixed {
        bottom: auto;
    }
}