.top-link {
    background-color: $primary;
    color: $white;
    display: flex;
    height: 3rem;
    width: 3rem;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity $transition;
    text-decoration: none;

    @include media-breakpoint-up(sm) {
        position: fixed;
        bottom: $spacer * 4;
        right: $spacer * 3;
        z-index: 900;
    }
    &:focus,
    &:hover,
    &:active {
        background-color: rgba($primary, .6);
        color: $white;
        text-decoration: none;
    }

    &.active {
        opacity: 1;
        pointer-events: auto;
    }
}