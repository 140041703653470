// SCSS component for ce_gallery.
// (c) 2019 Rapid Data GmbH
// last change: 19.11.2019 by ak
//
// Usage:
// Include somewhere after your variables, so defaults
// are overridden where desired.

// DO NOT change default values here - override them in
// your own variables file!

// Colors
$tiny-slider_nav-bg-color:      $primary   !default;
$tiny-slider_nav-color:         $white     !default;

// Image properties
$tiny-slider_image-width:       100%  !default;
$tiny-slider_image-height:      200px !default;
$tiny-slider_image-margin-v:    0     !default;
$tiny-slider_image-margin-h:    auto  !default;
$tiny-slider_image-radius:      0%    !default;

// Section properties
$tiny-slider_margin-top:        0     !default;
$tiny-slider_margin-bottom:     0     !default;
$tiny-slider_padding-h:         4rem  !default;
$tiny-slider_padding-top:       0     !default;
$tiny-slider_padding-bottom:    0     !default;

// Pagination settings
$tiny-slider_pagination-padd-v: 1rem  !default;
$tiny-slider_pagination-height: 50px  !default;

// Owl carousel settings
$tiny-slider_owl-show-dots:           true    !default;
$tiny-slider_owl-dot-size:            18px    !default;
$tiny-slider_stage-margin-h:          2rem    !default;
$tiny-slider_nav-elem-width:          48px    !default;
$tiny-slider_nav-elem-height:         64px    !default;
$tiny-slider_nav-elem-border-radius:  0       !default;
$tiny-slider_nav-elem-font-size:      2rem    !default;
$tiny-slider_nav-elem-padding-top:    0.25rem !default;
$tiny-slider_nav-elem-padding-bottom: 0.5rem  !default;

.ce_gallery--tiny-slider {
    position: relative;
    margin-top: $tiny-slider_margin-top;
    margin-bottom: $tiny-slider_margin-bottom;
    padding: $tiny-slider_padding-top $tiny-slider_padding-h $tiny-slider_padding-bottom;

    &__controls {
        position: absolute;
        width: 100%;
        top: calc(50% - 1rem);
        left: 0;
        right: 0;
        margin: 0;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        // Deactivate pointer events as the nav-container overlays the entire
        // carousel
        pointer-events: none;

        .slider-controls__control {
            background-color: $tiny-slider_nav-bg-color;
            border: none;
            border-radius: $tiny-slider_nav-elem-border-radius;
            color: $tiny-slider_nav-color;
            font-size: $tiny-slider_nav-elem-font-size;
            padding-top: $tiny-slider_nav-elem-padding-top;
            padding-bottom: $tiny-slider_nav-elem-padding-bottom;
            pointer-events: initial; // Reactivate pointer events as they are disabled on the parent
            transition: all .2s linear;

            &:hover {
                background-color: transparentize($tiny-slider_nav-bg-color, .2);
            }

            &.disabled {
                opacity: 0;
            }

            &--prev {
                left: -3rem;
            }

            &--next {
                right: -3rem;
            }

        }

    }

    .tns-ovh {
        overflow: hidden;
    }
    //
    .tns-nav {
        display: flex;
        position: relative !important;
        justify-content: center;
        align-items: center;
        bottom: 0;
        margin-top: .5rem;
        margin-bottom: .5rem;

        > [aria-controls] {
            @if ($tiny-slider_owl-show-dots) {
                display: block;
                background-color: transparentize($primary, .75);
                height: $tiny-slider_owl-dot-size;
                width: $tiny-slider_owl-dot-size;
                padding: 0;
                margin: 0 5px;
                border-radius: 50%;
                border: 0;

                &.tns-nav-active {
                    background-color: transparentize($primary, .3);
                }
            } @else {
                height: 0;
            }
        }
    }
}
