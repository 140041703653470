.progress {
    margin-bottom: 2rem;
}

.widget-pagebreak {
    .btn-default {
        background-color: rgba($primary, .75);
        color: $white;

        &:hover {
            background-color: $primary;
        }
    }
}