.card {
    &--collapsible{
        margin-bottom: $grid-gutter-width / 2;
    }
}

.card-header {
    border-bottom: 0;
    padding: 0;
    button {
        display: flex;
        padding: $card-spacer-y $card-spacer-x ;
        width: 100%;
        text-align: left;
        font-size: $h3-font-size;
        white-space: normal;
        justify-content: space-between;
        align-items: center;
        @include animationLine;

        &:focus,
        &:hover {
            text-decoration: none;
        }



        svg {
            border-radius: 50%;
            height: 1.25em;
            background-color: transparent;
            color: $primary;
            transform-origin: unset!important;
            transition: transform $transition;

            &.fa-angle-down {
                color: $white;
                background-color: $primary;
            }
        }

        &[aria-expanded="true"] .fa-angle-down {
            @include rotate(180);
        }
    }
}

.card-body {
    section {
        margin-bottom: $spacer!important;
    }

    .card--collapsible & {
        max-height: 250px;
        position: relative;
        overflow: hidden;
        transition: $transition-base;
        padding-bottom: 4rem;

        &::after {
            content: '';
            position: absolute;
            height: 90px;
            left: 0;
            right: 0;
            bottom: 3rem;
            pointer-events: none;
            @include gradient-y(transparent, $white,0,100%);
        }
    }

    .card--collapsible.expanded & {
        max-height: 2500px;
        transition: $transition-base;

        &::after {
            background-image: none;
        }
    }
}

.gp-functions {
    @include media-breakpoint-down(md) {
        .col-md {
            margin-bottom: $spacer;
        }

    }
    .card {
        height: 100%;
    }

    .card-header button {
        justify-content: flex-start;
        pointer-events: none;

        .fas {
            margin-right: $spacer;
        }
    }

    .fa-angle-down {
        display: none;
    }
}


.card-toggler {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;

    .expanded &__text-open {
        display: none;
    }

    &__text-close {
        display: none;
    }

    .expanded &__text-close {
        display: block;
    }
}