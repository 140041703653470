//this allows the faq-cards to be collapsed. mod_faqlist--category-collapse--with-card-collapse.html5 only adds an button to the .card-header
.mod_faqlist {
    #faq-tabs {
        justify-content: center;

        .nav-item {
            flex: initial;
        }
    }

    .tab-content {

        //-having column-count here will cause the cards to rearrange when one is expanded
        .card-columns {
            column-count: initial;
            column-gap: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .card {
                margin-bottom: 3rem;
                max-height: 300px;
                align-self: flex-start;
                overflow: hidden;
                position: relative;
                transition: all 0.2s ease-in-out;

                //white shadow if collapsed
                &:after {
                    content: "";
                    height: 90px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-image: linear-gradient(to bottom, rgba(237, 237, 237, 0) 0, #ededed 100%);
                    background-repeat: repeat-x;
                }

                @include media-breakpoint-up(lg) {
                    width: 48%;
                    margin-left: 1%;
                    margin-right: 1%;
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                .card-header {
                    button {
                        &:before {
                            content: none;
                        }
                    }
                }

                &.expanded {
                    max-height: initial;

                    //remove white shadow
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
}

.mod_faqlist {
    .nav-item:not(:last-child) {
        .nav-link {
            border-right: 1px solid $body-bg;
        }
    }

    .nav-link {
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);

        &:before {
            content: " ";
            position: absolute;
            z-index: -1;
            left: 51%;
            right: 51%;
            bottom: 0;
            background: $primary;
            height: 3px;
            -webkit-transition-property: left, right;
            transition-property: left, right;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }

        &:hover, &.active {
            &:before {
                left: 0;
                right: 0;
            }
        }
    }
}