[class*="ajaxform--"] {
    position: relative;
    opacity: 1;
    transition: opacity .25s ease-out;

    .loading-message {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        transition: all .25s ease-in;
    }

    form.is-loading + .loading-message {
        opacity: 1;
        pointer-events: auto;
        transition: all .25s ease-out;
    }

    form.is-loading {
        opacity: .25;
        transition: opacity .25s ease-in;
    }
}
