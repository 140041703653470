@font-face {
    font-family: 'fontello';
    src: url('../fonts/icons/fontello.eot?89145195');
    src: url('../fonts/icons/fontello.eot?89145195#iefix') format('embedded-opentype'),
    url('../fonts/icons/fontello.woff2?89145195') format('woff2'),
    url('../fonts/icons/fontello.woff?89145195') format('woff'),
    url('../fonts/icons/fontello.ttf?89145195') format('truetype'),
    url('../fonts/icons/fontello.svg?89145195#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?89145195#fontello') format('svg');
  }
}
*/

.fa-icon:before {
    content: attr(data-icon);
}

.fontello-icon:before {
    content: attr(data-icon);
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-downloads:before { content: '\e800'; } /* '' */
.icon-erbrecht:before { content: '\e801'; } /* '' */
.icon-friedhof_02:before { content: '\e803'; } /* '' */
.icon-patientenverfuegung:before { content: '\e805'; } /* '' */
.icon-seebestattung:before { content: '\e806'; } /* '' */
.icon-standort:before { content: '\e807'; } /* '' */
.icon-termine:before { content: '\e808'; } /* '' */
.icon-testament:before { content: '\e809'; } /* '' */
.icon-trauerratgeber:before { content: '\e80a'; } /* '' */
.icon-aktuelles:before { content: '\e80c'; } /* '' */
.icon-anonyme_bestattung:before { content: '\e80d'; } /* '' */
.icon-anzeigen:before { content: '\e80e'; } /* '' */
.icon-bestattungsarten:before { content: '\e80f'; } /* '' */
.icon-blumen:before { content: '\e810'; } /* '' */
.icon-erinnerungsbuch:before { content: '\e811'; } /* '' */
.icon-erste_schritte:before { content: '\e812'; } /* '' */
.icon-formalitaeten:before { content: '\e813'; } /* '' */
.icon-foto:before { content: '\e814'; } /* '' */
.icon-fragen_antworten:before { content: '\e816'; } /* '' */
.icon-kerze:before { content: '\e817'; } /* '' */
.icon-kleidung:before { content: '\e818'; } /* '' */
.icon-kondolenzen:before { content: '\e81a'; } /* '' */
.icon-kosten_2:before { content: '\e81c'; } /* '' */
.icon-kosten_3:before { content: '\e81d'; } /* '' */
.icon-mail:before { content: '\e81e'; } /* '' */
.icon-naturbestattung:before { content: '\e81f'; } /* '' */
.icon-seebestattung_02:before { content: '\e821'; } /* '' */
.icon-spenden:before { content: '\e822'; } /* '' */
.icon-telefon:before { content: '\e823'; } /* '' */
.icon-trauercafe:before { content: '\e824'; } /* '' */
.icon-trauerdruck:before { content: '\e825'; } /* '' */
.icon-trauermusik:before { content: '\e826'; } /* '' */
.icon-urne:before { content: '\e827'; } /* '' */
.icon-vorsorge:before { content: '\e828'; } /* '' */
.icon-vorsorgevertrag:before { content: '\e829'; } /* '' */
.icon-vorsorgevollmacht:before { content: '\e82a'; } /* '' */
.icon-weltraumbestattung:before { content: '\e82b'; } /* '' */
.icon-auto:before { content: '\e82c'; } /* '' */
.icon-baumbestattung:before { content: '\e82d'; } /* '' */
.icon-diamant_bestattung:before { content: '\e82e'; } /* '' */
.icon-kosten:before { content: '\e82f'; } /* '' */
.icon-knigge:before { content: '\e830'; } /* '' */
.icon-leistungen:before { content: '\e831'; } /* '' */
.icon-sarg:before { content: '\e832'; } /* '' */
.icon-urne2:before { content: '\e833'; } /* '' */
.icon-friedhof:before { content: '\e834'; } /* '' */
.icon-fotoalben:before { content: '\e835'; } /* '' */
.icon-abschied:before { content: '\e836'; } /* '' */