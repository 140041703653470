.image_container {
    overflow: hidden;
    position: relative;

    .ce_image & {
        display: inline-block;
        margin-bottom: $spacer * 5;
    }

    figcaption {
        margin-top: 5px;
    }

}

.ce_bs_grid_gallery {
    figure {
        margin-bottom: $grid-gutter-width;
    }

    figcaption {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 0;
        padding: $spacer/2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $white;
        background-color: rgba($primary,.9);
        transition: max-height 0.3s ease-out, opacity 0.3s ease;
        opacity: 0;
        margin-top: 0;
    }

    figure:hover figcaption {
        max-height: 2.4rem * $gallery-figcation-lines;
        opacity: 1;
    }
}
.img-thumbnail {
    border-radius: 0;
    background-color: transparent;
    padding: 0;
}

.figure-img {
    margin-bottom: 0;
}

.cboxElement img {
    transform: scale(1);
    transition: $transition;

    &:hover {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}