// SCSS component for GP-Carousel.
// (c) 2018 Rapid Data GmbH
// last change: 08.02.2018 by fv
//
// Usage:
// Include somewhere after your variables, so defaults
// are overridden where desired.

// DO NOT change default values here - override them in
// your own variables file!

// Colors
//$primary:                       #d60600  !default;
//$white:                         #ffffff  !default;
$gp-carousel_nav-bg-color:      $primary   !default;
$gp-carousel_nav-color:         $white     !default;

// Image properties
$gp-carousel_image-width:       100%  !default;
$gp-carousel_image-height:      200px !default;
$gp-carousel_image-margin-v:    0     !default;
$gp-carousel_image-margin-h:    auto  !default;
$gp-carousel_image-radius:      0%    !default;

// Section properties
$gp-carousel_margin-top:        0     !default;
$gp-carousel_margin-bottom:     0     !default;
$gp-carousel_padding-h:         4rem  !default;
$gp-carousel_padding-top:       0     !default;
$gp-carousel_padding-bottom:    0     !default;

// Pagination settings
$gp-carousel_pagination-padd-v: 1rem  !default;
$gp-carousel_pagination-height: 50px  !default;

// Owl carousel settings
$gp-carousel_owl-show-dots:           true    !default;
$gp-carousel_owl-dot-size:            18px    !default;
$gp-carousel_stage-margin-h:          2rem    !default;
$gp-carousel_nav-elem-width:          48px    !default;
$gp-carousel_nav-elem-height:         64px    !default;
$gp-carousel_nav-elem-border-radius:  0       !default;
$gp-carousel_nav-elem-font-size:      2rem    !default;
$gp-carousel_nav-elem-padding-top:    0.25rem !default;
$gp-carousel_nav-elem-padding-bottom: 0.5rem  !default;

.memorials-overview {
    position: relative;
    margin-top: $gp-carousel_margin-top;
    margin-bottom: $gp-carousel_margin-bottom;
    padding: $gp-carousel_padding-top $gp-carousel_padding-h $gp-carousel_padding-bottom;

    .memorials-overview__content--slider_controls {
        position: absolute;
        width: 100%;
        top: calc(50% - 1rem);
        left: 0;
        right: 0;
        margin: 0;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        // Deactivate pointer events as the nav-container overlays the entire
        // carousel
        pointer-events: none;

        .slider-controls__control {
            background-color: $gp-carousel_nav-bg-color;
            border: none;
            border-radius: $gp-carousel_nav-elem-border-radius;
            color: $gp-carousel_nav-color;
            font-size: $gp-carousel_nav-elem-font-size;
            padding-top: $gp-carousel_nav-elem-padding-top;
            padding-bottom: $gp-carousel_nav-elem-padding-bottom;
            pointer-events: initial; // Reactivate pointer events as they are disabled on the parent
            transition: all .2s linear;

            &:hover {
                background-color: transparentize($gp-carousel_nav-bg-color, .2);
            }

            &.disabled {
                opacity: 0;
            }

            &--prev {
                left: -3rem;
            }

            &--next {
                right: -3rem;
            }

        }

    }

    .tns-ovh {
        overflow: hidden;

        .tns-inner {
            .tns-item {
                .card {
                    .decease-image {
                        display: block;

                        img {
                            width: $gp-carousel_image-width !important;
                            height: $gp-carousel_image-height !important;
                            object-fit: cover;
                            margin: $gp-carousel_image-margin-v $gp-carousel_image-margin-h;
                            border-radius: $gp-carousel_image-radius;
                        }
                    }
                }
            }
        }
    }

    .tns-nav {
        display: flex;
        position: relative !important;
        justify-content: center;
        align-items: center;
        bottom: 0;
        margin-top: .5rem;
        margin-bottom: .5rem;

        > [aria-controls] {
            @if ($gp-carousel_owl-show-dots) {
                display: block;
                background-color: transparentize($primary, .75);
                height: $gp-carousel_owl-dot-size;
                width: $gp-carousel_owl-dot-size;
                padding: 0;
                margin: 0 5px;
                border-radius: 50%;
                border: 0;

                &.tns-nav-active {
                    background-color: transparentize($primary, .3);
                }
            } @else {
                height: 0;
            }
        }
    }

    &__slider {
        display: flex;
    }

}
