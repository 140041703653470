// Basic framework styles
#{if(&, "&", "*")}::-webkit-scrollbar {
    width: 12px;
    -webkit-border-radius: $border-radius;
}
#{if(&, "&", "*")}::-webkit-scrollbar-track {
    background: transparentize($secondary, .75);
    background-blend-mode: multiply;
    -webkit-border-radius: 1px;
    border-radius: 1px;
}
#{if(&, "&", "*")}::-webkit-scrollbar-thumb {
    background: transparentize($primary, .5);
    -webkit-border-radius: $border-radius;
    border-radius: $border-radius;
}

iframe {
    border: 0;
}

figure {
    margin-bottom: 0;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

body {
    overflow-y: scroll;
    @if $fade-in-page == true {
        animation: fadein 2.5s
    }
}

cookie-banner.open {
    right: 0;
}

.bg-text {
    background-color: $body-color;
}

.bg-primary-light {
    background-color: rgba($primary, .3);
}

.js-hidden {
    opacity: 0;
    transition: opacity .25s ease;
}

@include create-standard-spacing();

.max-container-width {
    @include make-container-max-widths();
}

.heading-left {
    h1, h2, h3, h4, h5 {
        text-align: left !important;
    }
}

.list-inline {
    list-style: none;

    li {
        display: inline;
    }
}

.inline-list-divider {
    &:not(:last-child):after {
        content: ' |';
        color: $body-color;
    }
}

.icon-flip::before {
    transform: rotateY(180deg);
}

.flip {
    transform: rotateY(180deg);
}

.invisible {
    display: none;
}

.no-overflow {
    overflow: hidden;
}

.cursor-pointer {
    cursor: pointer
}

.card-columns {
    column-count: 3;
    @include media-breakpoint-down(md) {
        column-count: 2;
    }
    @include media-breakpoint-down(sm) {
        column-count: 1;
    }
}

.hide-on-ios {
    .ios & {
        display: none;
    }
}
.showonly-on-ios {
    display: none;
    .ios & {
        display: block;
    }
}

*[data-icon]:before {
    content: attr(data-icon);
}

@media print {
    video,audio {
        display: none;
    }
    .container {
        max-width: 100%;
    }
    .embed-responsive {
        display: none;
    }
}