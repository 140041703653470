.price-chart {

    .legend {
        list-style: none;

        &__legend-item, .legend__item {
            display: block;
            line-height: 1.5rem;
        }

        &__dot {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
        }

        &__value {
            line-height: 1.5rem;
            vertical-align: top;
        }
    }
}
