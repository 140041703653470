@mixin boxShadow($offsetX, $offsetY, $blur, $spread, $color) {
  -webkit-box-shadow: $offsetX $offsetY $blur $spread $color;
  -moz-box-shadow: $offsetX $offsetY $blur $spread $color;
  box-shadow: $offsetX $offsetY $blur $spread $color;
}

@mixin boxShadowInset($offsetX, $offsetY, $blur, $spread, $color) {
  -webkit-box-shadow: $offsetX $offsetY $blur $spread inset $color;
  -moz-box-shadow: $offsetX $offsetY $blur $spread inset $color;
  box-shadow: $offsetX $offsetY $blur $spread inset $color;
}

@mixin cssArrow($direction, $size, $color) {
  @if ($direction == 'left') {
    width: 0;
    height: 0;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  } @else if ($direction == 'right') {
    width: 0;
    height: 0;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if ($direction == 'up') {
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  } @else {
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  }
}

@mixin boxShadowLeft($size) {
  position: relative;
  &:before {
    box-shadow: -#{$size} 0 #{$size} -#{$size} transparentize(black, .7) inset;
    content: " ";
    height: 100%;
    left: -#{$size};
    position: absolute;
    top: 0;
    width: #{$size};
  }
}

@mixin boxShadowRight($size) {
  position: relative;
  &:after {
    box-shadow: #{$size} 0 #{$size} -#{$size} transparentize(black, .7) inset;
    content: " ";
    height: 100%;
    right: -#{$size};
    position: absolute;
    top: 0;
    width: #{$size};
  }
}

@mixin boxShadowTop($size) {
  position: relative;
  &:after {
    box-shadow: 0 -#{$size} #{$size} -#{$size} transparentize(black, .7) inset;
    content: " ";
    width: 100%;
    top: -#{$size};
    position: absolute;
    left: 0;
    height: #{$size};
  }
}

@mixin hoverBorderEffect($size, $borderColor, $bgColor) {
  border: $size solid $borderColor;
  margin: 1px;
  background-color: $bgColor;
}

@mixin borderRadiusWhenEnabled() {
  @if ($enable-rounded) {
    border-radius: $border-radius;
  } @else {
    border-radius: 0 !important;
  }
}

/// Test if `$value` is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-keyword or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
  }

  $conversion-map: (
          to top : bottom,
          to top right : bottom left,
          to right top : left bottom,
          to right : left,
          to bottom right : top left,
          to right bottom : left top,
          to bottom : top,
          to bottom left : top right,
          to left bottom : right top,
          to left : right,
          to left top : right bottom,
          to top left : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - $value;
}

@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin transform($transforms) {
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin scale($size) {
  @include transform(scale(#{$size}));
}

@mixin flip($size-x, $size-y) {
  @include transform(scale($size-x, $size-y));
}

@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

@mixin object-fit($position, $fit) {
  position: absolute;
  object-fit: $fit;
  object-position: $position;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin noMargin {
  margin-left: -($grid-gutter-width/2);
  margin-right: -($grid-gutter-width/2);
}

@mixin imageRoundedBorder {
  position: relative;
  &:after {
    content: "";
    width: 107%;
    height: 107%;
    border: 1px solid $colorPrimary;
    border-radius: 50%;
    position: absolute;
    left: -3.5%;
    top: -3.5%;
  }
}

@mixin create-standard-spacing() {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @each $prop, $abbrev in (margin: m, padding: p) {
        .#{$abbrev}#{$infix}-std {
          #{$prop}: map_get($std-spacings, $abbrev) !important;
        }

        .#{$abbrev}t#{$infix}-std,
        .#{$abbrev}y#{$infix}-std {
          #{$prop}-top: map_get($std-spacings, $abbrev) !important;
        }

        .#{$abbrev}r#{$infix}-std,
        .#{$abbrev}x#{$infix}-std {
          #{$prop}-right: map_get($std-spacings, $abbrev) !important;
        }

        .#{$abbrev}b#{$infix}-std,
        .#{$abbrev}y#{$infix}-std {
          #{$prop}-bottom: map_get($std-spacings, $abbrev) !important;
        }

        .#{$abbrev}l#{$infix}-std,
        .#{$abbrev}x#{$infix}-std {
          #{$prop}-left: map_get($std-spacings, $abbrev) !important;
        }

        .#{$abbrev}#{$infix}-gutter2x {
          #{$prop}: $grid-gutter-width !important;
        }

        .#{$abbrev}t#{$infix}-gutter2x,
        .#{$abbrev}y#{$infix}-gutter2x {
          #{$prop}-top: $grid-gutter-width !important;
        }

        .#{$abbrev}r#{$infix}-gutter2x,
        .#{$abbrev}x#{$infix}-gutter2x {
          #{$prop}-right: $grid-gutter-width !important;
        }

        .#{$abbrev}b#{$infix}-gutter2x,
        .#{$abbrev}y#{$infix}-gutter2x {
          #{$prop}-bottom: $grid-gutter-width !important;
        }

        .#{$abbrev}l#{$infix}-gutter2x,
        .#{$abbrev}x#{$infix}-gutter2x {
          #{$prop}-left: $grid-gutter-width !important;
        }

        .#{$abbrev}#{$infix}-gutter4x {
          #{$prop}: $grid-gutter-width*2 !important;
        }

        .#{$abbrev}t#{$infix}-gutter4x,
        .#{$abbrev}y#{$infix}-gutter4x {
          #{$prop}-top: $grid-gutter-width*2 !important;
        }

        .#{$abbrev}r#{$infix}-gutter4x,
        .#{$abbrev}x#{$infix}-gutter4x {
          #{$prop}-right: $grid-gutter-width*2 !important;
        }

        .#{$abbrev}b#{$infix}-gutter4x,
        .#{$abbrev}y#{$infix}-gutter4x {
          #{$prop}-bottom: $grid-gutter-width*2 !important;
        }

        .#{$abbrev}l#{$infix}-gutter4x,
        .#{$abbrev}x#{$infix}-gutter4x {
          #{$prop}-left: $grid-gutter-width*2 !important;
        }
      }
    }
  }
}

@mixin m--gutterWidth {
  margin-left: -($grid-gutter-width/2);
  margin-right: -($grid-gutter-width/2);
}

@mixin px-0 {
  padding-left: 0;
  padding-right: 0;
}

@mixin animationLine {
  position: relative;
  transform: perspective(1px) translateZ(0);
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: $primary;
    height: 3px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  &:focus::before,
  &:hover::before,
  .active &::before,
  &.active::before {
    left: 0;
    right: 0;
  }

}

@mixin headings() {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    @content
  }
}

@mixin display-headings() {
  .display-1, .display-2, .display-3, .display-4 {
    @content
  }
}