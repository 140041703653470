.mod_pageImage {
    .image_container {
        position: relative;
        @include media-breakpoint-down(lg){
            height: 30vh;
        }
        @include media-breakpoint-up(lg){
            height: 40vh;
        }


        img {
            @include object-fit(center, cover);
        }
    }
}