.teaser {
    background-color: $white;
    transition: background-color $transition;
    box-shadow: $box-shadow;
    height: 100%;
    border: 1px solid $card-border-color;

    &--icon {
        font-size: 8rem;
        margin-bottom: $spacer;
        display: inline-block;
        color: $primary;
        transition: $transition-base;
        line-height: 1;
        width: 100%;
        height: auto;

        &:before {
            margin: 0;
        }
    }

    &--name {
        margin-top: $spacer;
    }

    &--link {
        display: block;
        padding: $spacer * 4 $spacer * 2;
        text-decoration: none;


        &:hover {
            text-decoration: none;
        }
    }

    &:hover {
        background-color: rgba($primary, .3);
    }
}

//#main #teaser {
//    @include m--gutterWidth;
//    margin-top: $spacer * 4;
//    padding: 0!important;
//    .col-md {
//        &:nth-child(odd) {
//            background-color: rgba($body-color, 0.05);
//        }
//    }
//}