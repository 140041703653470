footer {
    @extend .py-std;
    background-color: $footer-bg-color;
    color: $footer-color;

    a {
        color: $footer-color;
        text-decoration: underline;
        &:hover {
            color: $footer-color;
            text-decoration: none;
        }
    }

    body:not(.home) & {
        @extend .border-top;
    }

    .address {
        font-size: 1rem;
        margin-bottom: $spacer/2;
    }

    .mod_navigation {
        margin-bottom: $spacer * 2;

        ul {
            margin-bottom: $spacer;
        }

        .list-inline-item:not(:last-child) {
            margin-right: $spacer*2;
        }
    }
}
