.memorials-overview__content--slider {
    .card {
        text-align: center;
        border-width: 0;
        background-color: transparent;
    }

    button {
        width: 3rem;
        height: 3rem;
        line-height: 2rem;
        padding: 0;

        &[disabled] {
            cursor: not-allowed;
            opacity: .5;
        }
    }
}