.container-fluid {
    #main & {
        @extend .py-std;
    }
}

#navigation {
    position: sticky;
    z-index: 15;
    top: 0;
    background-color: $primary;
}
.container-fluid + .container-fluid {
    margin-bottom: 0;
}

#container {
    @include px-0;
    background-color: rgba($body-color, 0.05);

    section, .ce_bs_grid_gallery {
        margin-bottom: $spacer*3;
    }
}

.jumbotron {
    position: relative;
}