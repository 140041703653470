.logo {
    @include media-breakpoint-up(md) {
        @if $logo-margin == true {
            margin-bottom: -($spacer * 2);
        }
        margin-left: $spacer;
        position: relative;
        z-index: 50;
        max-width: $logo-max-width;
    }

    img {
        display: inline;
        background-color: $white;
        padding: $logo-padding;
    }
}

header {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
    background-color: rgba($white, .9);

    .telefon {
        @include font-size(1.563rem);
        font-family: $headings-font-family;
    }
}